import {Container,Pagination,Col,Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Topbar from "../Topbar/Topbar";
import Searchbar from "../Searchbar/Searchbar";
import {useState, useEffect, useRef} from 'react';
import { FetchInterceptor } from '../secure/AuthInterceptor';
import LoadingBar from 'react-top-loading-bar';
import ImageResults from './ImageResults.js'
import { useNavigate } from 'react-router-dom';

const ImagesSetting = () => {
  let controller = new AbortController();
  let signal = controller.signal;
  const navigate = useNavigate();
  const ref = useRef(null);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  async function getAllImagesFromAPI(){
    let pageSize=10;
    ref.current.continuousStart();
    let url=process.env.REACT_APP_SERVER_URL +'getAllImages';
    const requestOptions = {
        signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({
          "company":"danieletest2",
          "page":currentPage,
          "page_size":pageSize
        })
    };

   FetchInterceptor(navigate);
   await fetch(url,requestOptions,{signal})
    .then((res) => res.json())
    .then((data) => {
      setTotalPages(data.n_pages);
      setData(data.images);
      ref.current.complete();
      })
    .catch(error => {
      console.log(error);
    });
  } 
  useEffect(() => {
    getAllImagesFromAPI();
  }, [currentPage]);

  const pageChangeFunction = (p) => {
    if (p >= 1 && p <= totalPages) {
        setCurrentPage(p);
    }
  };
  const showPageItemsFunction = () => {
  
    const data = [];
    const numPage = 10;
    let leftSide = currentPage - numPage / 2 > 1;
    let rightSide = currentPage + numPage / 2 < totalPages;
    if (leftSide && rightSide) {
        leftSide = currentPage - numPage / 2 > 2;
        rightSide = currentPage + numPage / 2 < totalPages - 1;
    }
    data.push(
        <Pagination.First
            key="first"
            onClick={() => pageChangeFunction(1)}
        />
    );
    data.push(
        <Pagination.Prev
            key="prev"
            onClick={() => pageChangeFunction(currentPage - 1)}
        />
    );
    if (leftSide) {
        data.push(
            <Pagination.Ellipsis
                key="leftEllipsis"
                className="ellipsis"
                onClick={() =>{ pageChangeFunction((currentPage+1) - numPage)}}
            />
        );
    }
    const str = Math.max(1, Math.round(currentPage - numPage / 2));
    const end = Math.min(totalPages, Math.round(currentPage + numPage / 2));
    for (let i = str; i <= end; i++) {
        data.push(
            <Pagination.Item
                key={i}
                active={i === currentPage}
                onClick={() => pageChangeFunction(i)}
            >
                {i}
            </Pagination.Item>
        );
    }
    if (rightSide) {
        data.push(
            <Pagination.Ellipsis
                key="rightEllipsis"
                className="ellipsis"
                onClick={() => {pageChangeFunction((currentPage-1) + numPage)}}
            />
        );
    }
    data.push(
        <Pagination.Next
            key="next"
            onClick={() => pageChangeFunction(currentPage + 1)}
        />
    );
    data.push(
        <Pagination.Last
            key="last"
            onClick={() => pageChangeFunction(totalPages)}
        />
    );
    return data;
};
  
  return(
    <>
    <LoadingBar color='rgba(254, 70, 123, 1)' ref={ref} />
    <Topbar page="ImagesSetting"/>
    <Searchbar page="ImagesSetting"/>
    <Container className='article-container' fluid>
        {
          data.length>0?
          <>
            <Row xs={1} md={1} className="g-4 article-row">
              <ImageResults results={data} page='ImagesSetting' getAllImagesFromAPI={getAllImagesFromAPI}/>
            </Row>    
            <Row className="g-4 pagination-row mtop">
              <Col><Pagination>{showPageItemsFunction()}</Pagination></Col>
            </Row>
          </>:<></>
        }
    </Container>
</>
  )
  };

export default ImagesSetting;

