
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Topbar from "../Topbar/Topbar";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './ViewPDF.css';
import Button from 'react-bootstrap/Button';
import { FaChevronLeft } from "react-icons/fa";
import {environment} from '../environments/environment';
import {useSearchParams } from "react-router-dom";
import {useEffect, useState} from 'react';
import axiosHttp from "../secure/axiosHttp";
import React, { useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';

const ViewPDF = () => {
    const ref = useRef(null)
    const [searchParams, setSearchParams] = useSearchParams();
    const[pdfURL,setPdfURL]=useState('');
    const pdfData =JSON.parse(searchParams.get("data"));
    const fileExtension = pdfData.pdfName.split('.').pop().toLowerCase();
    removeParam('data');
    useEffect(() => {
        ref.current.continuousStart();
        let url='';
        if(fileExtension==='pdf' || fileExtension === 'docx'){
            // If the file is a DOCX, change the extension to PDF
            if (fileExtension === 'docx') {
                pdfData.pdfName = pdfData.pdfName.replace(/\.docx$/i, '.pdf');
                console.log("pdf name: ", pdfData.pdfName);
            }
            url=process.env.REACT_APP_SERVER_URL+'viewDocument/'+pdfData.pdfName + '?page_number=' + pdfData.page_number;
            axiosHttp.get(url,{responseType: 'arraybuffer'})
                .then(res=>{
                    const data=res.data;
                    const blobdata=new Blob([data], { type: 'application/pdf' });
                    const pdfURL=URL.createObjectURL(blobdata)+"#page="+pdfData.page_number;
                    setPdfURL(pdfURL);
                    ref.current.complete()
                })
                .catch(error=>{
                    const data=error.response.data;
                    const blobdata=new Blob([data], { type: 'application/pdf' });
                    const pdfURL=URL.createObjectURL(blobdata)+"#page="+pdfData.page_number;
                    setPdfURL(pdfURL);
                    ref.current.complete()
                })
        }
        else if(fileExtension.toLowerCase()==='xlsx'){
            url=process.env.REACT_APP_SERVER_URL+'viewDocument/'+pdfData.pdfName;
            axiosHttp.get(url,{responseType: 'arraybuffer'})
                .then(res=>{
                    const data=res.data;
                    const blobdata=new Blob([data], { type: 'application/pdf' });
                    const pdfURL=URL.createObjectURL(blobdata);
                    setPdfURL(pdfURL);
                    ref.current.complete()
                })
                .catch(error=>{
                    const data=error.response.data;
                    const blobdata=new Blob([data], { type: 'application/pdf' });
                    const pdfURL=URL.createObjectURL(blobdata);
                    setPdfURL(pdfURL);
                    ref.current.complete()
                })
        }
        else{
            url=process.env.REACT_APP_SERVER_URL+'viewDocument/'+pdfData.pdfName;
            fetchVideo(url);
        }
    }, []);

    async function fetchVideo(url){
        const response = await fetch(url);
        const reader = response.body.getReader();
        const contentLength = +response.headers.get('Content-Length');
        let receivedLength = 0; // Received bytes
        const chunks = []; // Stream chunks
        while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            chunks.push(value);
            receivedLength += value.length;
            // Create a Blob from the chunks and set the video source
            const blob = new Blob(chunks);
            const videoURL = URL.createObjectURL(blob);
            setPdfURL(videoURL);
        }
    }

    function removeParam(parameter)
    {
        var url=document.location.href;
        var urlparts= url.split('?');

        if (urlparts.length>=2)
        {
            var urlBase=urlparts.shift();
            var queryString=urlparts.join("?");

            var prefix = encodeURIComponent(parameter)+'=';
            var pars = queryString.split(/[&;]/g);
            for (var i= pars.length; i-->0;)
                if (pars[i].lastIndexOf(prefix, 0)!==-1)
                    pars.splice(i, 1);
            url = urlBase+pars.join('&');
            if(fileExtension.toLowerCase()==='pdf')
                window.history.pushState('',document.title,url+'/'+pdfData.pdfName+'#page='+pdfData.page_number); // added this line to push the new url directly to url bar .
            else
                window.history.pushState('',document.title,url+'/'+pdfData.pdfName);
        }
        return url;
    }
    return(
        <>
            <LoadingBar color='rgba(254, 70, 123, 1)' ref={ref} />
            <Topbar/>
            <Container  fluid>
                <Row className='container-rows-1'>
                    <Col xs={12} md={12}>
                        <Button className="back-button" onClick={()=>window.close()}><FaChevronLeft/><span>GO BACK TO YOUR SEARCH</span></Button>
                    </Col >
                </Row>
                <Row className='container-rows-2'>
                    <Col xs={12} md={12}>
                        {
                            (fileExtension.toLowerCase()==='xlsx' || fileExtension.toLowerCase()==='pdf' || fileExtension.toLowerCase() === 'docx') ?
                                <iframe src={pdfURL} text={pdfData.url}  className='iframe-container'></iframe>
                                :
                                <>
                                    <div style={{height: '700px',margin:'50px'}}>
                                        <video src={pdfURL} preload="auto" controls="true" style={{width: '100%', height: '100%'}}>
                                        </video></div>
                                </>
                        }
                    </Col >
                </Row>
            </Container>
        </>
    )
};

export default ViewPDF;



