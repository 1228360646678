import React,{useState} from "react";
import {Container, Row, Col, Button, Modal,Card,Form,InputGroup, Image} from 'react-bootstrap';
import { FetchInterceptor } from '../secure/AuthInterceptor';
import { Link } from "react-router-dom";
import "./SearchResults.css";
import { FaFile, FaFilePdf} from 'react-icons/fa';
import { ImPencil } from "react-icons/im";
import { FaSearch } from 'react-icons/fa'; 

const FileIcon = ({ extension }) => {
  const iconMap = {
    pdf: <FaFilePdf />,
    default: <FaFile />,
  };
  const icon = iconMap[extension.toLowerCase()] || iconMap.default;
  return <div className="image-file-icon">{icon}</div>;
};

 const ImageResults = ({results,page,getAllImagesFromAPI}) =>{
  const isAdmin=localStorage.getItem('isAdmin');
  const [searchTerm, setSearchTerm] = useState('');
  const [show, setShow] = useState(false);
  const [imageData,setImageData]=useState('');
  const handleClose = () => setShow(false);
  const [showContent, setShowContent] = useState(false);
  const [title,setTitle]=useState('');
  const [descData,setDescData]=useState('');
  const handleCloseContent = () => setShowContent(false);
  const [textValue, setTextValue] = useState('');
  const [idval, setIdVal] = useState('');
  

  const handleSearch = (e) => {
    e.preventDefault();
    // Implement your search logic here, like fetching or filtering data
    console.log('Searching for:', searchTerm);
  };

  function zoomImage(imgData,descriptionData,descriptionTitle){
    setShow(true);
    setImageData(imgData);
    setDescData(descriptionData);
    setTitle(descriptionTitle);
  }

  function showDescDialog(descriptionData,descriptionTitle,id){
    setShowContent(true);
    setTitle(descriptionTitle);
    setDescData(descriptionData);
    setIdVal(id);
    setTextValue(descriptionData);
  }

  const handleChange = (e) => {
    setTextValue(e.target.value);
  }

  async function updateDescription(){
    try {
      const url=process.env.REACT_APP_SERVER_URL + 'updateDescriptions'
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "company": "danieletest2",
          "updates": [
            {
              "id": idval,
              "updated_description": textValue
            }
          ]
        })
    };
    FetchInterceptor();
    await fetch(url,requestOptions)
    .then(response=>response.json())
    .then(res=>console.log(res)) 
    } catch (error) {
      console.log(error);
    }
  }

  function handleSubmit(){
    if(descData!=textValue){
      updateDescription();
      }
      setTimeout(() => {
        getAllImagesFromAPI();  
      }, 2000);
      setShowContent(false);
  }
  
    return(
      <>
      {isAdmin==="true" && page==='ImagesSetting'?
        <Form onSubmit={handleSearch} style={{'marginLeft':'1%'}}>
        <Form.Label htmlFor="basic-url">Documents</Form.Label>
        <InputGroup style={{'width': '25%'}}>
          <InputGroup.Text id="search-icon">
            <FaSearch />
          </InputGroup.Text>
          <Form.Control
            type="search"
            aria-label="Search"
            aria-describedby="search-icon"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
      </Form>:<></>
      }
        
       {
            results.map((data,index)=>{
              return(<Col id={index} xs={12} sm={6} md={4} lg={3} className='d-flex justify-content-center mb-4 custom-col'>
                <Card className="border-0 image-card">
                <div className="image-item" key={index} onClick={()=>zoomImage('data:image/png;base64,'+data.image_data,data.description,data.image)}>
                  <img src={'data:image/png;base64,'+data.image_data}  />
                </div>
                  <Card.Body className="card-body-Image">
                    <div className="image-title d-flex">
                      <span><FileIcon extension={data.pdfName.split('.').pop().toLowerCase()} /></span>
                      <Link className="img-desc" to={'/viewpdf?data='+encodeURIComponent(JSON.stringify({pdfName:data.pdfName,page_number:data.page_number}))} target="_blank">{data.image}</Link>
                      <span style={{'color':'rgba(158, 158, 167, 1)'}}>{isAdmin==="true" && page==='ImagesSetting'?<ImPencil onClick={()=>showDescDialog(data.description,data.image,data.id)} className="hand-cursor" title='Edit' />:<></>}</span>
                    </div>
                  </Card.Body>
                  { isAdmin==="true" && page==='ImagesSetting'?
                      <Card.Footer className="image-cards-footer subtitle-text" onClick={()=>zoomImage('data:image/png;base64,'+data.image_data,data.description,data.image)}>
                      {data.description}
                      </Card.Footer>:<></>  
                  }
                </Card>
              </Col>)
          })}
          <Modal show={show} size="xl" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header className="desc-modal-header" closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="zoomed-image">
              <Image src={imageData}/>
            </Modal.Body>
            <Modal.Footer>
              <p>{descData}</p>
            </Modal.Footer>
          </Modal>      

          <Modal show={showContent} size="xl" onHide={handleCloseContent} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header className="desc-modal-header" closeButton >
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="desc-modal-body">
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Edit Description</Form.Label>
                  <Form.Control as="textarea" defaultValue={descData} onChange={handleChange} rows={20} autoFocus/>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseContent}>
                Close
              </Button>
              <Button variant="primary" onClick={()=>handleSubmit()}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>               
      </>
    )
}

export default ImageResults;