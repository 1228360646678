import {Container, Row, Col, InputGroup, Form} from 'react-bootstrap';
import {FaSearch} from "react-icons/fa";
import React, {useState } from "react"; 

const Searchbar = ({setFilterTextVal,textval,page,setOldTextValue}) => { 
    const [searchEnabled,setSearchEnabled]=useState(true);
        return(
            <>
                { 
                    page=="Home" || page=="MyDocuments"  || page=="ImagesSetting"?
                        <Container fluid>
                            <Row className="colored-row justify-content-center">
                            </Row>
                        </Container>
                        :
                        <Container fluid>
                            <Row className="colored-row justify-content-center">
                                <Col xs={11} md={8} className="search-column"> 
                                    <InputGroup className="shadow bg-white input-group-whole" >
                                        <Form.Control placeholder="Search*"  defaultValue={textval} onKeyDown={(event)=>{if(event.key==="Enter"){if(searchEnabled===false)alert('Please enter a valid text or question!');if(searchEnabled===true) {setOldTextValue()}}}} onChange={(e)=>{if(e.target.value.length==0){setSearchEnabled(false);} else if(e.target.value.length>0){setSearchEnabled(true);setFilterTextVal(e.target.value)}}} className="input-field" />
                                        <InputGroup.Text className="search-icon-border"  onClick={()=>{if(searchEnabled===false)alert('Please enter a valid text or question!');if(searchEnabled===true) {setOldTextValue()}}} ><FaSearch/></InputGroup.Text>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Container>            
                }
            </>
        )
}

export default Searchbar;    