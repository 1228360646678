
import {Container, Card, Row, Col, Button} from 'react-bootstrap';
import Topbar from "../Topbar/Topbar";
import './MyDocuments.css';
import '../Searchbar/Searchbar'
import Searchbar from "../Searchbar/Searchbar";
import React, { useEffect, useState, useRef} from "react"; 
import {environment} from '../environments/environment';
import LoadingBar from 'react-top-loading-bar'
import axiosHttp from "../secure/axiosHttp"; 
import { MdOutlineSync } from "react-icons/md";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { TiTick } from "react-icons/ti";
import AppPagination from '../Pagination/Paginate'

const MyDocuments = () => { 
  const [show, setShow] = useState(false);
  const [docdata,setDocdata]=useState([]);
  const ref = useRef(null);

  async function fetchDocuments(){
    try {
      ref.current.continuousStart()
      const url=process.env.REACT_APP_SERVER_URL + 'getDocs';
      const response =  await axiosHttp.get(url);
      setDocdata(response.data);
      ref.current.complete()
    } catch (error) {
      ref.current.complete()
      console.error('Error:', error);
    }
  }
  async function syncDocuments(){
    try{
    const url=process.env.REACT_APP_SERVER_URL + 'sync';
    const response = await axiosHttp.get(url); 
    if(response.data.message==="Success")
      setShow(true);
      await syncImages(); 
    }catch (error) {
      console.error('Error:', error);
    }
  }

  async function syncImages() {
    const url = process.env.REACT_APP_SERVER_URL + 'syncImages';
    try {
      const response = await axiosHttp.get(url);
      if (response.data.message === "Success") {
        setShow(true);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
    
  useEffect(() => {
      fetchDocuments();
  }, []);

    return(
        <>  
            <LoadingBar color='rgba(254, 70, 123, 1)' ref={ref} />
            <Topbar page="MyDocuments" fetchDocuments={fetchDocuments}/>
            <Searchbar page="MyDocuments"/>
            <Container class="mydoc-container">
              <Row className='top-bar'>
                <Col xs={6} md={3} className='navbar justify-content-start justify-content-start'>
                <label className="rectangular-box"></label><span className="span-title">My Documents</span> 
                </Col>
                <Col xs={6} md={6}></Col>
                <Col xs={6} md={3} className='navbar justify-content-end justify-content-end'>
                <Button className="add-files-button" onClick={syncDocuments}><MdOutlineSync /><span>Sync</span></Button>
                </Col>
                <ToastContainer position="top-end" className="p-3"  style={{ zIndex: 1 }}>
                <Toast bg='Success' onClose={() => setShow(false)} show={show} delay={1500} autohide>
                  <Toast.Body className="bg-success text-white"><TiTick />Data synced</Toast.Body>
                </Toast>
                </ToastContainer>
              </Row>
                {docdata.length>0 ? <AppPagination data={docdata} fetchDocuments={fetchDocuments} page="MyDocuments"/> : <></>}
            </Container>
        </>
        )
  }
  
  export default MyDocuments;        