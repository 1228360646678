import {Row, Col,Pagination} from 'react-bootstrap';
import React, { Component } from 'react';
import SearchResults from '../Search/SearchResults.js'
import ImageResults from '../Search/ImageResults.js'
import DocTable from '../MyDocuments/TableBody.js'
import {useState, useEffect} from 'react';
import '../Pagination/Paginate.css'

const AppPagination = ({data,page,fetchDocuments,buttonId}) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize,setPageSize] = useState(10);
    let [paginatedData,setPaginatedData] = useState([])

    useEffect(()=>{
        setPaginatedData(
            data.slice(
                (currentPage - 1) * pageSize,
                currentPage * pageSize
              )
        )
      },[currentPage])

      useEffect(()=>{
        setCurrentPage(1);
        setTotalPages(Math.ceil(data.length / pageSize));
        setPaginatedData(
            data.slice(
                (currentPage - 1) * pageSize,
                currentPage * pageSize
              )
        )
      },[data])


    const pageChangeFunction = (p) => {
        if (p >= 1 && p <= totalPages) {
            setCurrentPage(p);
        }
    };
    const showPageItemsFunction = () => {
        const data = [];
        const numPage = 10;
        let leftSide = currentPage - numPage / 2 > 1;
        let rightSide = currentPage + numPage / 2 < totalPages;
        if (leftSide && rightSide) {
            leftSide = currentPage - numPage / 2 > 2;
            rightSide = currentPage + numPage / 2 < totalPages - 1;
        }
        data.push(
            <Pagination.First
                key="first"
                onClick={() => pageChangeFunction(1)}
            />
        );
        data.push(
            <Pagination.Prev
                key="prev"
                onClick={() => pageChangeFunction(currentPage - 1)}
            />
        );
        if (leftSide) {
            data.push(
                <Pagination.Ellipsis
                    key="leftEllipsis"
                    className="ellipsis"
                    onClick={() =>{ pageChangeFunction((currentPage+1) - numPage)}}
                />
            );
        }
        const str = Math.max(1, Math.round(currentPage - numPage / 2));
        const end = Math.min(totalPages, Math.round(currentPage + numPage / 2));
        for (let i = str; i <= end; i++) {
            data.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => pageChangeFunction(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }
        if (rightSide) {
            data.push(
                <Pagination.Ellipsis
                    key="rightEllipsis"
                    className="ellipsis"
                    onClick={() => {pageChangeFunction((currentPage-1) + numPage)}}
                />
            );
        }
        data.push(
            <Pagination.Next
                key="next"
                onClick={() => pageChangeFunction(currentPage + 1)}
            />
        );
        data.push(
            <Pagination.Last
                key="last"
                onClick={() => pageChangeFunction(totalPages)}
            />
        );
        return data;
    }
    return(
        <>
            {
            page=="MyDocuments" ?
                <>
                <Row>
                <DocTable data={paginatedData} fetchDocuments={fetchDocuments} />
                </Row>
                <Row className="g-4 pagination-row mtop">
                    <Col><Pagination>{showPageItemsFunction()}</Pagination></Col>
                </Row>
                </>
                :
                <>
                <Row xs={1} md={1} className="g-4 article-row">
                {buttonId==='Text'?<SearchResults results={paginatedData}/>:<ImageResults results={paginatedData} page={page}/>}
                </Row>    
                <Row className="g-4 pagination-row mtop">
                    <Col><Pagination>{showPageItemsFunction()}</Pagination></Col>
                </Row>
                </>
            } 
        </>
        
    )
}

export default AppPagination;