import React from "react";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import "./SearchResults.css";
import { FaFile, FaFilePdf} from 'react-icons/fa';
import { SiGoogledocs } from "react-icons/si";
import { TbFileTypeTxt } from "react-icons/tb";
import { BsFillFileEarmarkExcelFill } from "react-icons/bs";
import { MdOutlineOndemandVideo } from "react-icons/md";

const FileIcon = ({ extension }) => {
  const iconMap = {
    pdf: <FaFilePdf />,
    mp4: <MdOutlineOndemandVideo />,
    txt: <TbFileTypeTxt />,
    doc: <SiGoogledocs />,
    docx: <SiGoogledocs />,
    xlsx: <BsFillFileEarmarkExcelFill />,
    default: <FaFile />,
  };
  const icon = iconMap[extension.toLowerCase()] || iconMap.default;
  return <div className="file-icon">{icon}</div>;
};

 const SearchResults = ({results}) =>{
    return(
      <>
       {
            results.map((data,index)=>{
              return(<Col id={index} >
                <Card className="border-0">
                  <Card.Body className="search-card-body">
                    <Card.Text className="search-title">{
                      <>
                      <FileIcon extension={data.pdfName.split('.').pop().toLowerCase()} />
                      <Link to={'/viewpdf?data='+encodeURIComponent(JSON.stringify({pdfName:data.pdfName,page_number:data.page_number}))} target="_blank" className="tag-a">{data.pdfName}</Link>
                      </>
                    }
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer className="cards-footer-search-result">
                  {data.answer}
                  </Card.Footer>
                </Card>
              </Col>)
              
              
          })}
      </>
    )
}

export default SearchResults;