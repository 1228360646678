import fetchIntercept from 'fetch-intercept';

export const FetchInterceptor = () => {
    fetchIntercept.register({
      request: function (url, config) {
        // Modify the url or config here
        config.headers.Authorization = localStorage.getItem('token');
        return [url, config];
      },
  
      requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
      },
  
      response: function (response) {
        // Modify the reponse object
        return response;
      },
  
      responseError: function (error) {
          const {status} = error.response==undefined?{}:error.response;
          switch (status) {
          case 400:
              console.log(error.response);
              break;
          case 401:
              console.log("Unauthorized");
              break;
          case 404:
              console.log(error.response.status+' : '+error.response.statusText);
              break;
          case 415:
              console.log(error.response.status+' : '+error.response.statusText);
              break;
          case 500:
              console.log("server error");
              break;
          default:
              console.log("an unknown error occurred");
              break;
          }
        
        return Promise.reject(error);
      },
    });
  };