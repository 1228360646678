import { ImFilePdf } from "react-icons/im";
import { ImPencil } from "react-icons/im";
import { IoClose } from "react-icons/io5";
import Table from 'react-bootstrap/Table';
import {environment} from '../environments/environment';
import axiosHttp from "../secure/axiosHttp"; 
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {useState} from "react"; 

const TableBody =({data,fetchDocuments,renameDocument})=>{
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [oldFileName,setOldFileName]=useState('');
    const [newFileName,setNewfileName]=useState('');

    async function deleteDocument(filename){
        if (window.confirm(`Are you sure you want to delete ${filename}?`)) {
            const url=environment.SERVER_URL + 'deleteDocument/' + filename;
            const response = await axiosHttp.delete(url); 
            if(response.data.message==="Success")
            fetchDocuments();
        }
    }

    function onNameChange(e){
        setNewfileName(e.target.value);
    }

    function renameDocument(oldFileName){
        setOldFileName(oldFileName)
        handleShow();
    }
    async function getNewName(){
        const fileUpdateUrl = environment.SERVER_URL + "updateFileName";
        const response = await axiosHttp.post(fileUpdateUrl,{oldFileName:oldFileName,newFileName:newFileName}); 
        if(response.data.success===true){
            fetchDocuments();
        }
        handleClose();
    }
    
    return(
        <>
            <Table striped>
                <thead>
                  <tr>
                    <th></th>
                    <th>File Name</th>
                    <th>Date Uploaded</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                    {data.map((document)=>
                    <tr>
                    <td><ImFilePdf /></td>
                    <td>{document.filename}</td>
                    <td>{new Date(document.created_date).toLocaleDateString()}</td>
                    <td><ImPencil onClick={event=>renameDocument(document.filename)} className="hand-cursor" title='Rename file' /></td>
                    <td><IoClose onClick={()=>deleteDocument(document.filename)} title='Delete Document'className="hand-cursor"/></td> 
                    </tr>
                    )}
                </tbody> 
            </Table>
            <Modal show={show} onHide={handleClose}> 
                <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Control plaintext readOnly defaultValue={oldFileName} />
                    <Form.Control type="text" onChange={onNameChange} placeholder="Enter new name" />
                    </Form.Group>
                </Form>
                
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>    
                    <Button variant="primary" onClick={getNewName}>
                        Rename
                    </Button>
                </Modal.Footer>
          </Modal>
        </>
    )
}
export default TableBody;