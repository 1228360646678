import Topbar from "../Topbar/Topbar";
import Searchbar from "../Searchbar/Searchbar";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import {FaSearch} from "react-icons/fa";
import Image from 'react-bootstrap/Image';
import SocrateLogo from '../assets/socrate_logo_2.png';
import { Link,useNavigate } from "react-router-dom";
import {useState,} from 'react';
import './Home.css';
import Button from 'react-bootstrap/Button';

const Home = () => { 
    const navigate = useNavigate()
    const [textVal,setTextVal]=useState();
    const handleEnterClick= ()=>{
        console.log('enter');
        navigate('/search',{state:textVal});
    }
  return(
        <>
        <Topbar page="Home"/>
        <Searchbar page="Home" />
        <Container fluid>
            <Row className="justify-content-center">
                <Col xs={12} md={6} className="col-content"> 
                    <Image src={SocrateLogo} />
                    <InputGroup className="shadow bg-white input-group-home">
                        <Form.Control placeholder="Search*" onChange={(e)=>setTextVal(e.target.value)} onKeyDown={(event)=>{if(event.key==="Enter")handleEnterClick()}} className="input-field"/>
                        <InputGroup.Text className="search-icon-border" state={textVal}  to='/search' as={Link}><FaSearch/></InputGroup.Text>
                    </InputGroup>
                </Col>                       
            </Row>                  
        </Container>
        </>
       )
}

export default Home;        