export const environment = {
  SERVER_URL: 'http://0.0.0.0:9092/socrate/',
  //SERVER_URL: 'http://18.102.7.181:9092/socrate/',
  firebaseConfig: {
      apiKey: 'AIzaSyAFbl_RGx1IG-o4dtoBV8hT0ZulUBfxWw0',
      authDomain: 'socrate-156a7.firebaseapp.com',
      projectId: 'socrate-156a7',
      storageBucket: 'socrate-156a7.appspot.com',
      messagingSenderId: '366901244709',
      appId: '1:366901244709:web:79f2dd3a581d41d94b60cc',
      measurementId: 'G-S8679GJYCV',
    },    
    //SERVER_URL:'https://socrate.e-plato.com/socrate/'
    //SERVER_URL: 'https://elearningai.fassitech.com/socrate/',
  };
