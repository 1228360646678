import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "./Topbar/Topbar.css";
import "./Searchbar/Searchbar.css";
import "./Search/Search.css";
import './index.css';
import Login from './Login-Signup/Login';
import Signup from './Login-Signup/Signup';
import Search from './Search/Search';
import ViewPDF from './ViewPDF/ViewPDF';
import Home from './Home/Home';
import  MyDocuments from './MyDocuments/MyDocuments';
import ImagesSetting from './Search/ImagesSetting';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
  <BrowserRouter>
    <Routes>
          <Route path="/" element={<Login/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/signup" element={<Signup/>} />
          <Route path="/search" element={<Search/>} />
          <Route path="/viewpdf" element={<ViewPDF/>} />
          <Route path="/mydocuments" element={<MyDocuments/>} />
          <Route path="/imagessetting" element={<ImagesSetting/>} />
    </Routes>
  </BrowserRouter>
    
  </>
);
