import axios from "axios";
import {environment} from '../environments/environment';

const axiosHttp = axios.create({
  baseURL: environment.SERVER_URL,
});

axiosHttp.interceptors.request.use(
  (config) => {
    const token =  localStorage.getItem('token')
    return {
      ...config,
      headers: {
        ...(token !== null && { Authorization: `${token}` }),
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosHttp.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const {status} = error.response;
    switch (status) {
      case 400:
        console.log(error.response);
        break;
      case 401:
        console.log("Unauthorized");
        break;
      case 404:
        console.log(error.response.status+' : '+error.response.statusText);
        break;
      case 415:
        console.log(error.response.status+' : '+error.response.statusText);
        break;
      case 500:
        console.log("server error");
        break;
      default:
        console.log("an unknown error occurred");
        break;
    }
    return Promise.reject(error);
  }
);

export default axiosHttp;